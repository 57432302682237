import React from 'react'
import { FieldLine } from './styles'

function EmployeeDetailsCVLinkField({ linkCV, linkName }) {
  return linkCV ? (
    <FieldLine>
      {linkCV && (
        <a href={linkCV} target="_blank" rel="noreferrer">
          {linkName}
        </a>
      )}
    </FieldLine>
  ) : (
    <FieldLine>{linkName}</FieldLine>
  )
}

export default EmployeeDetailsCVLinkField
