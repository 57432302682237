import React, { useContext, useState } from 'react'
import { Radio } from 'antd'
import { RUB, USD } from 'constants/currency'
import { RateCurrencyContext } from 'constants/rateCurrencyContext'
import { setCurrency } from 'services/currencies'
import { DEFAULT_RATE_CURRENCY } from '../../constants/currency'

function RateCurrencySwitch() {
  const { currentCurrency, setCurrentCurrency } = useContext(
    RateCurrencyContext
  )

  const hanldeChange = newCurrency => {
    setCurrentCurrency(prev => ({
      ...prev,
      ...{ type: newCurrency }
    }))
    setCurrency(newCurrency)
  }

  return (
    <>
      <Radio.Group
        onChange={event => hanldeChange(event.target.value)}
        defaultValue={DEFAULT_RATE_CURRENCY.type}
      >
        {currentCurrency.type === RUB && (
          <Radio.Button value={USD}>{USD}</Radio.Button>
        )}
        {currentCurrency.type === USD && (
          <Radio.Button value={RUB}>{RUB}</Radio.Button>
        )}
      </Radio.Group>
    </>
  )
}

export default RateCurrencySwitch
