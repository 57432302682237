import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import Modal from 'components/Modal'
import MediaQuery from 'react-responsive'
import Archives from 'components/ArchivesContainer'
import NewProjectCard from 'components/NewProjectCard'
import StaffContainer from 'components/StaffContainer'
import AvailableStaff from './components/AvailableStaff'
import AvailableStaffModal from 'components/AvailableStaffModal'
import SearchInput from './components/SearchInput'
import MobileMenu from './components/MobileMenu'
import {
  Container,
  LogoImg,
  NavContainer,
  NavMenu,
  MenuStaffItem,
  MenuAddItem,
  MenuArchivesItem,
  MenuAvailableStaffItem,
  MenuSearchItem,
  MenuLogoutItem,
  MenuLocaleSwitchItem
} from './styles'
import kLogo from 'images/kLogo.png'
import { removeTokens } from 'services/auth'
import { LAPTOP_OR_DESKTOP } from 'constants/breakpoints'
import LocaleSwitch from 'components/LocaleSwitch'
import RateCurrencySwitch from 'components/RateCurrencySwitch'

function Header({ search, setSearch }) {
  const { t } = useTranslation('Header')
  const history = useHistory()
  const [NewEmployeeCardIsOpen, setNewEmployeeCardIsOpen] = useState(false)
  const [NewProjectCardIsOpen, setNewProjectCardIsOpen] = useState(false)
  const [ArchivesIsOpen, setArchivesIsOpen] = useState(false)
  const [availableStaffModalIsOpen, setAvailableStaffModalIsOpen] = useState(
    false
  )
  const [availableStaffs, setAvailableStaffs] = useState([])

  function toggleNewEmployeeCardModal() {
    setNewEmployeeCardIsOpen(!NewEmployeeCardIsOpen)
  }

  function toggleNewProjectCardModal() {
    setNewProjectCardIsOpen(!NewProjectCardIsOpen)
  }

  function toggleArchivesModal() {
    setArchivesIsOpen(!ArchivesIsOpen)
  }

  const logout = () => {
    removeTokens()
    history.push('/login')
  }

  function toggleAvailableStaffModal() {
    setAvailableStaffModalIsOpen(!availableStaffModalIsOpen)
  }

  const navMenu = (
    <>
      <MenuStaffItem key="1" onClick={toggleNewEmployeeCardModal}>
        {t('Staffs')}
      </MenuStaffItem>
      <MenuAddItem key="2" onClick={toggleNewProjectCardModal}>
        {t('AddNewProject')}
      </MenuAddItem>
      <MenuArchivesItem key="3" onClick={toggleArchivesModal}>
        {t('Archives')}
      </MenuArchivesItem>
      <MenuAvailableStaffItem key="4">
        <AvailableStaff
          setAvailableStaffs={setAvailableStaffs}
          toggleAvailableStaffModal={toggleAvailableStaffModal}
        />
      </MenuAvailableStaffItem>
      <MenuSearchItem key="5">
        <SearchInput search={search} setSearch={setSearch} />
      </MenuSearchItem>
      <MenuLogoutItem key="6" onClick={() => logout()}>
        {t('Logout')}
      </MenuLogoutItem>
      <MenuLocaleSwitchItem key="7">
        <LocaleSwitch />
        <RateCurrencySwitch />
      </MenuLocaleSwitchItem>
    </>
  )

  return (
    <>
      <Container>
        <LogoImg src={kLogo} alt="logo"></LogoImg>
        <NavContainer>
          <MobileMenu navMenu={navMenu} />
          <MediaQuery minWidth={LAPTOP_OR_DESKTOP}>
            <NavMenu selectable={false}>{navMenu}</NavMenu>
          </MediaQuery>
        </NavContainer>
      </Container>
      <Modal
        showModal={NewEmployeeCardIsOpen}
        closeModal={toggleNewEmployeeCardModal}
      >
        <StaffContainer closeModal={toggleNewEmployeeCardModal} />
      </Modal>
      <Modal
        showModal={NewProjectCardIsOpen}
        closeModal={toggleNewProjectCardModal}
      >
        <NewProjectCard closeModal={toggleNewProjectCardModal} />
      </Modal>
      <Modal showModal={ArchivesIsOpen} closeModal={toggleArchivesModal}>
        <Archives closeModal={toggleArchivesModal} />
      </Modal>
      <Modal
        showModal={availableStaffModalIsOpen}
        closeModal={toggleAvailableStaffModal}
      >
        <AvailableStaffModal
          availableStaffs={availableStaffs}
          toggleAvailableStaffModal={toggleAvailableStaffModal}
        />
      </Modal>
    </>
  )
}

export default Header
