import React, { useState } from 'react'
import { useQuery } from '@apollo/client'
import { useImmer } from 'use-immer'
import Header from 'views/Main/Header'
import Dashboard from 'views/Main/Dashboard'
import { DashboardContainer } from './styles'
import { DndStateContext } from 'constants/dndStateContext'
import ApolloSubscriptionsWrapper from 'components/ApolloDndSubscriptionsWrapper'
import { useDebounce } from 'use-debounce'
import { GET_PROJECTS } from 'api/project'
import { RateCurrencyContextProvider } from 'constants/rateCurrencyContextProvider'

function ProjectsBoard() {
  const [dndState, setDndState] = useImmer([])
  const [search, setSearch] = useState('')
  const [debouncedSearch] = useDebounce(search.trim(), 500)

  const { error, loading, data: projectData } = useQuery(GET_PROJECTS, {
    variables: { searchValue: debouncedSearch },
    onCompleted: () => {
      const projectDataArray = projectData?.projects.projects
      const projectDataArrayWithChunk = projectDataArray
        .map(project => ({
          ...project,
          projectConnection: project.projectConnection,
          isVisible: true
        }))
        .sort(
          (firstProject, secondProject) =>
            secondProject.rate - firstProject.rate
        )
      setDndState(draft => (draft = projectDataArrayWithChunk))
    },
    fetchPolicy: 'cache-and-network'
  })

  return (
    <DndStateContext.Provider value={{ dndState, setDndState }}>
      <RateCurrencyContextProvider>
        <ApolloSubscriptionsWrapper>
          <DashboardContainer>
            <Header search={search} setSearch={setSearch} />
            <Dashboard loading={loading && !projectData} error={error} />
          </DashboardContainer>
        </ApolloSubscriptionsWrapper>
      </RateCurrencyContextProvider>
    </DndStateContext.Provider>
  )
}

export default ProjectsBoard
