import React, { useContext } from 'react'
import { useDrop } from 'react-dnd'
import { Progress } from 'antd'
import StaffItem from '../StaffItem'
import full from 'images/full.png'
import {
  DropContainer,
  GridContainer,
  RateSquare,
  WorkTimeImg,
  CircularBar
} from './styles'
import { MAX_EMPLOYMENT_PERCENTAGE } from 'constants/employmentPercentageConst'
import { STAFF_ITEM } from 'constants/dndTypes'
import { workTypeCheck } from 'utils'
import { RateCurrencyContext } from 'constants/rateCurrencyContext'
import { calcRateInCurrentCurrency } from 'services/currencies'

function StaffContainer(props) {
  const { projectId, projectIndex, dndState, onDrop } = props
  const [collectedProps, drop] = useDrop(() => ({
    accept: STAFF_ITEM,
    drop: () => ({ projectId, projectIndex })
  }))
  const { currentCurrency } = useContext(RateCurrencyContext)

  return (
    <DropContainer ref={drop}>
      <GridContainer>
        {dndState?.map(
          ({ rateStaff, id, staff, employmentPercentage }, index) =>
            id && (
              <StaffItem
                key={id}
                rate={rateStaff}
                projectStaffId={id}
                employmentPercentage={employmentPercentage}
                staff={staff}
                projectId={projectId}
                indexItem={index}
                onDrop={onDrop}
              >
                <RateSquare
                  rate={calcRateInCurrentCurrency(
                    rateStaff,
                    currentCurrency.rate,
                    currentCurrency.type
                  )}
                >
                  {calcRateInCurrentCurrency(
                    rateStaff,
                    currentCurrency.rate,
                    currentCurrency.type
                  )}
                </RateSquare>
                {employmentPercentage !== MAX_EMPLOYMENT_PERCENTAGE ? (
                  <CircularBar>
                    <Progress
                      type="circle"
                      percent={employmentPercentage}
                      width={23}
                    />
                  </CircularBar>
                ) : null}
                {workTypeCheck(employmentPercentage) && (
                  <WorkTimeImg source={full} />
                )}
              </StaffItem>
            )
        )}
      </GridContainer>
    </DropContainer>
  )
}

export default StaffContainer
