import React from 'react'
import { Field } from 'react-final-form'
import { Form } from 'antd'
import { TextArea } from '../../style'

export function TextAreaField({
  fieldName,
  initialValue,
  labelText,
  staffFormTextAreaLength
}) {
  return (
    <Field name={fieldName}>
      {({ input: { onChange, value, name } }) => (
        <Form.Item
          label={labelText}
          name={name}
          onChange={onChange}
          initialValue={initialValue}
          value={value}
        >
          <TextArea maxLength={staffFormTextAreaLength} />
        </Form.Item>
      )}
    </Field>
  )
}
