import React, { useContext, useRef, useState } from 'react'
import Modal from 'components/Modal'
import { MasonryContainer } from './styles'
import ProjectCard from './components/ProjectCard'
import EditRateModal from 'components/EditRateModal'
import virtualProjectsInfo from 'data/virtual-projects-names.json'
import { BREAKPOINT_COLUMNS_OBJ } from 'constants/projectCardConst'
import VirtualProjectCard from './components/VirtualProjectCard'
import { DndStateContext } from 'constants/dndStateContext'
import useMoveStaff from './hooks/useMoveStaff'
import { message } from 'antd'
import { isEqual, isEqualWith } from 'lodash'

function ProjectCards() {
  const { dndState } = useContext(DndStateContext)
  const [isEditRate, setIsEditRate] = useState(false)
  const [editProjectStaffRateInfo, setEditProjectStaffRateInfo] = useState({})
  const moveStaff = useMoveStaff()
  const handleCloseModal = () => {
    setIsEditRate(!isEditRate)
  }

  const dndStateRef = useRef(dndState)

  if (!isEqualWith(dndStateRef.current, dndState, isEqual))
    dndStateRef.current = dndState

  const handleDrop = async moveData => {
    const { error, employmentPercentage } = moveStaff({
      ...moveData,
      dndState: dndStateRef.current
    })
    if (!error) {
      setEditProjectStaffRateInfo({
        staffId: moveData.staff.id,
        oldProjectId: moveData.sourceProject,
        projectId: moveData.destinationProject,
        employmentPercentage
      })
      setIsEditRate(moveData.sourceProject !== moveData.destinationProject)
    } else {
      message.error(error)
    }
  }

  const virtualProjects = virtualProjectsInfo.map(({ name }) => (
    <VirtualProjectCard key={name} projectName={name} onDrop={handleDrop} />
  ))

  const projects = dndState
    .map((currentProject, projectIndex) => (
      <ProjectCard
        key={currentProject.id}
        projectInfo={currentProject}
        projectIndex={projectIndex}
        onDrop={handleDrop}
      />
    ))
    .filter(({ props }) => props.projectInfo.isVisible)
    .sort(
      (firstProject, secondProject) => secondProject.rate - firstProject.rate
    )

  return (
    <>
      <MasonryContainer
        breakpointCols={BREAKPOINT_COLUMNS_OBJ}
        columnClassName="my-masonry-grid_column"
      >
        {virtualProjects.concat(projects)}
      </MasonryContainer>
      <Modal showModal={isEditRate} closeModal={handleCloseModal}>
        <EditRateModal
          closeModal={handleCloseModal}
          editProjectStaffRateInfo={editProjectStaffRateInfo}
        />
      </Modal>
    </>
  )
}

export default ProjectCards
