import React, { useState } from 'react'
import { RateCurrencyContext } from '../constants/rateCurrencyContext'
import { DEFAULT_RATE_CURRENCY } from './currency'
import { useQuery } from '@apollo/client'
import { DAILY_RATE } from 'api/dailyRate'

export const RateCurrencyContextProvider = ({ children }) => {
  const [currentCurrency, setCurrentCurrency] = useState(DEFAULT_RATE_CURRENCY)
  const { data } = useQuery(DAILY_RATE, {
    onCompleted: data => {
      setCurrentCurrency(prev => ({
        ...prev,
        ...{ rate: data?.getDailyRate?.dailyRate }
      }))
    }
  })

  return (
    <RateCurrencyContext.Provider
      value={{ currentCurrency, setCurrentCurrency }}
    >
      {children}
    </RateCurrencyContext.Provider>
  )
}
