import React, { useEffect } from 'react'
import { Radio } from 'antd'
import { useTranslation } from 'react-i18next'
import { getLocale, setLocale } from 'services/locales'
import { EN, RU } from 'constants/locales'

function LocaleSwitch() {
  const { i18n } = useTranslation()
  const currentLocate = getLocale() || RU

  useEffect(() => {
    i18n.changeLanguage(getLocale() || RU)
  }, [i18n])

  const hanldeChange = newLocale => {
    setLocale(newLocale)
    i18n.changeLanguage(newLocale)
  }

  return (
    <Radio.Group
      onChange={event => hanldeChange(event.target.value)}
      defaultValue={getLocale() || RU}
    >
      {currentLocate === EN && <Radio.Button value={RU}>RU</Radio.Button>}
      {currentLocate === RU && <Radio.Button value={EN}>EN</Radio.Button>}
    </Radio.Group>
  )
}

export default LocaleSwitch
