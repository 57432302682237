export const LAYOUT = {
  labelCol: {
    span: 9
  },
  wrapperCol: {
    span: 15
  }
}

export const staffFormFieldLength = 50
export const staffFormTextAreaLength = 255
